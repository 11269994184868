
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class AreYouSureModal extends Vue {

    @Prop() infoText: string;
    @Prop() successText: string;
    @Prop() denyText: string;

    @Prop() successCallback: any;
    @Prop() denyCallback: any;

    created() {
    }

}
