
import { Options, Vue } from 'vue-class-component';
import { QuestionAndAnswerClient } from '@/services/Services';
import EditQuestionModal from '../modal/editQuestionModal.vue';
import EditAnswerModal from '../modal/editAnswerModal.vue';
import AreYouSureModal from '@/modals/areYouSureModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class ChallengeConfiguration extends Vue {

    allQuestions: OM.QuestionAndAnswers[] = [];

    created() {
        this.init();
    }

    init() {
        QuestionAndAnswerClient.getAllQuestions()
        .then(x => {
            this.allQuestions = x;
        })
    }

    getText(notTranslatedText: OM.LocalizedValue) {
        return this.$localizationService.getTextFromValues(notTranslatedText.values);
    }

    toggleBlock(prefix: string, index: number) {
        var ref = (prefix + index).toString();
        var block = document.getElementById(ref);

        if(block.classList.contains("opened"))
            block.classList.remove("opened");
        else
            block.classList.add("opened");
    }

    editQuestion(questionAndAnswers: OM.QuestionAndAnswers) {
        var model = new OM.EditQuestionVM();
        
        if(questionAndAnswers != null) {
            model.questionIdentifier = questionAndAnswers.identifier;
            model.question = questionAndAnswers.question;
            model.correctTooltip = questionAndAnswers.correctToolTip;
            model.externalLink = questionAndAnswers.externalLink;
        }

        this.$opModal.show(EditQuestionModal, {
            model: model,
            callback: (updatedModel: OM.EditQuestionVM, file: File) => {
                model = updatedModel;
                QuestionAndAnswerClient.editQuestion(model)
                .then(x => {
                    QuestionAndAnswerClient.updateQuestionImage(x, file)
                    .then(x => {
                        this.$opModal.closeLast();
                        this.init();
                    })
                })
            }
        })
    }

    editAnswer(questionIdentifier: string, answer: OM.Answer) {
        var model = new OM.EditAnswerVM();
        model.questionIdentifier = questionIdentifier;

        if(answer != null) {
            model.answerIdentifier = answer.identifier;
            model.answer = answer.answerText;
            model.isCorrect = answer.isCorrect;
        }

        this.$opModal.show(EditAnswerModal, {
            model: model,
            callback: (updatedModel: OM.EditAnswerVM) => {
                model = updatedModel;
                QuestionAndAnswerClient.editAnswer(model)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }

    deleteQuestionAndAnswers(questionIdentifier: string) {
        this.$opModal.show(AreYouSureModal, {
            infoText: this.$localizationService.getLocalizedValue("backoffice_SeiSicuroDiVolerEliminareDomanda", "Sei sicuro di voler eliminare l'intera domanda e le risposte ad essa attribuite?"),
            denyText: this.$localizationService.getLocalizedValue("backoffice_Annulla", "Annulla"),
            successText: this.$localizationService.getLocalizedValue("backoffice_Conferma", "Conferma"),
            denyCallback: () => {
                this.$opModal.closeLast();
            },
            successCallback: () => {
                QuestionAndAnswerClient.deleteQuestionAndAnswers(questionIdentifier)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }

    deleteAnswer(questionIdentifier: string, answerIdentifier: string) {
        this.$opModal.show(AreYouSureModal, {
            infoText: this.$localizationService.getLocalizedValue("backoffice_SeiSicuroDiVolerEliminareRisposta", "Sei sicuro di voler eliminare questa risposta?"),
            denyText: this.$localizationService.getLocalizedValue("backoffice_Annulla", "Annulla"),
            successText: this.$localizationService.getLocalizedValue("backoffice_Conferma", "Conferma"),
            denyCallback: () => {
                this.$opModal.closeLast();
            },
            successCallback: () => {
                QuestionAndAnswerClient.deleteAnswer(questionIdentifier, answerIdentifier)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }
}
