
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { QuestionAndAnswerClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class EditQuestionModal extends Vue {

    @Prop({
        default: () => new OM.EditQuestionVM()
    }) model: OM.EditQuestionVM;
    localModel: OM.EditQuestionVM = new OM.EditQuestionVM();

    @Prop() callback: any;

    localFile: File = null;

    created() {
        this.localModel = JSON.parse(JSON.stringify(this.model));
        if(this.localModel.questionIdentifier) {
            QuestionAndAnswerClient.getImagePath(this.localModel.questionIdentifier)
            .then(x => {
                this.localModel.imageUrl = x;
            })
        }
    }

    save() {
        this.callback(this.localModel, this.localFile);
    }

}
