import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modal bg_grey d-flex justify-content-between align-items-center flex-column" }
const _hoisted_2 = { class: "page_padding text-center" }
const _hoisted_3 = { class: "page_padding w-100" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = { class: "form-group mb-0 w-auto" }
const _hoisted_6 = { class: "page_padding d-flex w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_ctx.localModel.answerIdentifier)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            type: "h1",
            class: "text-uppercase text-center fw_700",
            localizedKey: "backoffice_ModificaRisposta",
            text: "Modifica Risposta"
          }))
        : (_openBlock(), _createBlock(_component_localized_text, {
            key: 1,
            type: "h1",
            class: "text-uppercase text-center fw_700",
            localizedKey: "backoffice_NuovaRisposta",
            text: "Nuova Risposta"
          }))
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_localized_text, {
            class: "input_title text-nowrap",
            localizedKey: "backoffice_RispostaCorretta",
            text: "Risposta Corretta"
          }),
          _createVNode("div", {
            class: ["sameAsCheckbox", { 'checked' : _ctx.localModel.isCorrect }],
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.localModel.isCorrect = !_ctx.localModel.isCorrect))
          }, null, 2)
        ]),
        _createVNode(_component_form_field, {
          type: "localized",
          modelValue: _ctx.localModel.answer,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.localModel.answer = $event)),
          placeholderKey: "backoffice_Risposta",
          placeholderValue: "Risposta",
          labelKey: "backoffice_Risposta",
          labelValue: "Risposta",
          class: "mb-0 ms-4"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createVNode("div", _hoisted_6, [
      _createVNode("button", {
        class: "btn btn-secondary w-50 me-1",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "backoffice_Annulla",
          text: "Annulla"
        })
      ]),
      _createVNode("button", {
        class: "btn btn-primary w-50 ms-1",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.save()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "backoffice_Salva",
          text: "Salva"
        })
      ])
    ])
  ]))
}