import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modal bg_grey d-flex justify-content-between align-items-center flex-column" }
const _hoisted_2 = { class: "page_padding text-center" }
const _hoisted_3 = { class: "page_padding w-100" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = { class: "page_padding d-flex w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_select_file = _resolveComponent("select-file")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_ctx.localModel.questionIdentifier)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            type: "h1",
            class: "text-uppercase text-center fw_700",
            localizedKey: "backoffice_ModificaDomanda",
            text: "Modifica Domanda"
          }))
        : (_openBlock(), _createBlock(_component_localized_text, {
            key: 1,
            type: "h1",
            class: "text-uppercase text-center fw_700",
            localizedKey: "backoffice_NuovaDomanda",
            text: "Nuova Domanda"
          }))
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_select_file, {
          modelValue: _ctx.localFile,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.localFile = $event)),
          imageUrl: _ctx.localModel.imageUrl
        }, null, 8, ["modelValue", "imageUrl"]),
        _createVNode(_component_form_field, {
          type: "localized",
          modelValue: _ctx.localModel.question,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.localModel.question = $event)),
          placeholderKey: "backoffice_Domanda",
          placeholderValue: "Domanda",
          labelKey: "backoffice_Domanda",
          labelValue: "Domanda",
          class: "mb-0 ms-4"
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_form_field, {
        type: "localized",
        modelValue: _ctx.localModel.correctTooltip,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.localModel.correctTooltip = $event)),
        placeholderKey: "backoffice_CorrectTooltip",
        placeholderValue: "Correct Tooltip",
        labelKey: "backoffice_CorrectTooltip",
        labelValue: "Correct Tooltip",
        class: "mt-4"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_form_field, {
        type: "localized",
        modelValue: _ctx.localModel.externalLink,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.localModel.externalLink = $event)),
        placeholderKey: "backoffice_Link",
        placeholderValue: "Link",
        labelKey: "backoffice_Link",
        labelValue: "Link",
        class: "mt-4"
      }, null, 8, ["modelValue"])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("button", {
        class: "btn btn-secondary w-50 me-1",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "backoffice_Annulla",
          text: "Annulla"
        })
      ]),
      _createVNode("button", {
        class: "btn btn-primary w-50 ms-1",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.save()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "backoffice_Salva",
          text: "Salva"
        })
      ])
    ])
  ]))
}